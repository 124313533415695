import React, { useState } from "react";
import logos from "./temp-login-img.png";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilLockLocked } from "@coreui/icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { MdVisibilityOff } from "react-icons/md";
import { MdVisibility } from "react-icons/md";
import { useLocation } from "react-router-dom";

const ResetPassword = () => {
  const location = useLocation();
  const email = location.state && location.state.email;

  const navigate = useNavigate();

  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  const [type1, setType1] = useState("password");
  const [icon1, setIcon1] = useState(<MdVisibilityOff />);
  const [type2, setType2] = useState("password");
  const [icon2, setIcon2] = useState(<MdVisibilityOff />);

  const handlePassword1 = (e) => {
    setPassword1(e.target.value);
  };

  const handlePassword2 = (e) => {
    setPassword2(e.target.value);
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password1 !== password2) {
      Toast.fire({
        icon: "error",
        title: "Passwords do not match",
      });
      return;
    }

    const jsonData = {
      email: email,
      password: password1,
      password: password2,
    };
    await axios
      .post("resetPassword", jsonData, {
        headers: {
          accept: "application/json",
        },
      })
      .then((result) => {
        if (result.data.status === 400) {
          Toast.fire({
            icon: "error",
            title: result.data.message,
          });

          navigate("/new-password");
        } else {
          Toast.fire({
            icon: "success",
            title: result.data.message,
          });

          localStorage.removeItem("status");

          navigate("/");
        }
      })
      .catch((error) => {
        Toast.fire({
          icon: "error",
          title: error.response.data.message,
        });
      });
  };

  const handleToggle1 = (e) => {
    e.preventDefault();
    if (type1 == "password") {
      setIcon1(<MdVisibility />);
      setType1("text");
    } else {
      setIcon1(<MdVisibilityOff />);
      setType1("password");
    }
  };
  const handleToggle2 = (e) => {
    e.preventDefault();
    if (type2 == "password") {
      setIcon2(<MdVisibility />);
      setType2("text");
    } else {
      setIcon2(<MdVisibilityOff />);
      setType2("password");
    }
  };

  return (
    <div className="bg-white min-vh-100 d-flex flex-row align-items-center p-3">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={9}>
            <CCardGroup>
              <CCard className="py-2 px-3">
                <CCardBody>
                  <CForm onSubmit={handleSubmit}>
                    <h2 style={{ color: "#4a4843" }}>Reset Password</h2>

                    <CInputGroup className="my-4">
                      <CInputGroupText>
                        <CIcon className="text-black" icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type={type1}
                        placeholder="New Password"
                        onChange={handlePassword1}
                        required
                        value={password1}
                      />
                      <CInputGroupText>
                        <button
                          className="text-black"
                          onClick={handleToggle1}
                          style={{
                            background: "#fff",
                            border: "none",
                            fontSize: "20px",
                          }}
                        >
                          <span>{icon1}</span>
                        </button>
                      </CInputGroupText>
                    </CInputGroup>
                    <CInputGroup className="my-4">
                      <CInputGroupText>
                        <CIcon className="text-black" icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type={type2}
                        placeholder="Re-enter New Password"
                        onChange={handlePassword2}
                        required
                        value={password2}
                      />
                      <CInputGroupText>
                        <button
                          className="text-black"
                          onClick={handleToggle2}
                          style={{
                            background: "#fff",
                            border: "none",
                            fontSize: "20px",
                          }}
                        >
                          <span>{icon2}</span>
                        </button>
                      </CInputGroupText>
                    </CInputGroup>
                    <CRow>
                      <CCol xs={6} className="mb-4">
                        <CButton
                          type="submit"
                          className="bg-black text-white border-b px-5"
                        >
                          Submit
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              <CCard className="text-white bg-dark ">
                <CCardBody className="text-center">
                  <div
                    className=" d-flex mt-3"
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <img
                      src={logos}
                      alt=""
                      width="260"
                      className="sidebar-brand-full"
                    />
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default ResetPassword;
