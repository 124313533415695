import React, { useState } from "react";
import logos from "./temp-login-img.png";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilUser } from "@coreui/icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const adminlogin = new FormData();
    adminlogin.append("email", email);

    await axios
      .post("forgetPassword", adminlogin, {
        headers: {
          accept: "application/json",
        },
      })
      // .then((result) => {
      //   result.data.status == 400
      //     ? Toast.fire({
      //         icon: "error",
      //         title: result.data.message,
      //       })
      //     : Toast.fire({
      //         icon: "success",
      //         title: result.data.message,
      //       });

      //   result.data.status == 400
      //     ? navigate("/forget-password")
      //     : navigate("/otp-verify", { state: { email } });

      //   localStorage.setItem("status", result?.data?.data?.token);
      // })
      .then((result) => {
        if (result.data.status === 400) {
          Toast.fire({
            icon: "error",
            title: result.data.message,
          });

          navigate("/forget-password");
          localStorage.removeItem("status");
        } else {
          Toast.fire({
            icon: "success",
            title: result.data.message,
          });

          navigate("/otp-verify", { state: { email } });
          localStorage.setItem("status", result?.data?.data?.token);
        }
      })
      .catch((error) => {
        Toast.fire({
          icon: "error",

          title: error.response.data.message,
        });
      });
  };

  return (
    <div className="bg-white min-vh-100 d-flex flex-row align-items-center p-3">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={9}>
            <CCardGroup>
              <CCard className="py-2 px-3">
                <CCardBody className="">
                  <CForm onSubmit={handleSubmit}>
                    <h2 className="text-center" style={{ color: "#4a4843" }}>
                      Enter Your Email
                    </h2>

                    <CInputGroup className="my-4">
                      <CInputGroupText>
                        <CIcon className="text-black" icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        style={{ height: 45 }}
                        placeholder="Email"
                        onChange={handleEmail}
                        required
                        value={email}
                        autoComplete="email"
                      />
                    </CInputGroup>

                    <CRow>
                      <p className="fw-light">
                        A 4 digit OTP will be sent via Email to verify your
                        Email Account
                      </p>
                      <CCol xs={6} className="mb-4">
                        <CButton
                          type="submit"
                          className="bg-black text-white border-b px-5"
                        >
                          Submit
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              <CCard className="text-white bg-dark ">
                <CCardBody className="text-center">
                  <div
                    className=" d-flex mt-3"
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <img
                      src={logos}
                      alt=""
                      width="260"
                      className="sidebar-brand-full"
                    />
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default ForgetPassword;
