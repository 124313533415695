import React, { useState } from "react";
import logos from "./temp-login-img.png";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CRow,
} from "@coreui/react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Toast } from "react-bootstrap";
import { useLocation } from "react-router-dom";
const OtpVerify = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state && location.state.email;
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("success"); // or "error"

  const [otp, setOtp] = useState(Array(4).fill(""));

  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (!isNaN(value) && value !== "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < otp.length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const jsonData = {
      email: email,
      otp: otp.join(""),
    };
    await axios
      .post("otpVerified", jsonData, {
        headers: {
          accept: "application/json",
        },
      })
      .then((result) => {
        setShowToast(true);

        result.data.status == 400
          ? navigate("/otp-verify")
          : navigate("/new-password", { state: { email } });
        setToastVariant("error");
        setToastMessage("Wrong Password! Please try again.");
      })
      .catch((error) => {
        setToastVariant("error");
        setToastMessage("An error occurred! Please try again.");
        setShowToast(true);
      });
  };

  const handleResendOtp = async () => {
    try {
      const jsonData = {
        email: email,
      };

      const result = await axios.post("resendotp", jsonData, {
        headers: {
          accept: "application/json",
        },
      });

      if (result.data.status == 200) {
        setToastVariant("success");
        setToastMessage("OTP resent successfully");
        setShowToast(true);
      } else {
        setToastVariant("error");
        setToastMessage("Failed to resend OTP. Please try again.");
        setShowToast(true);
      }
    } catch (error) {
      setToastVariant("error");
      setToastMessage("An error occurred! Please try again.");
      setShowToast(true);
    }
  };

  return (
    <CContainer className="min-vh-100 d-flex flex-row align-items-center p-3">
      <Toast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={3000}
        autohide
        style={{
          position: "absolute",
          top: "20px",
          left: "20px",
          zIndex: 1000,
        }}
      >
        <Toast.Header
          closeButton={false}
          className={`text-white bg-${toastVariant}`}
        >
          <strong className="mr-auto">
            {toastVariant == "success" ? "Success" : "Error"}
          </strong>
        </Toast.Header>
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
      <CRow className="justify-content-center">
        <CCol md={9}>
          <CCardGroup>
            <CCard className="py-2 px-3">
              <CCardBody className="">
                <CForm onSubmit={handleSubmit}>
                  <h2 className="text-center" style={{ color: "#4a4843" }}>
                    Enter Your OTP
                  </h2>

                  <CInputGroup className=" p-5">
                    {otp.map((digit, index) => (
                      <CFormInput
                        key={index}
                        type="text"
                        maxLength="1"
                        id={`otp-input-${index}`}
                        // value={digit}
                        onChange={(e) => handleOtpChange(e, index)}
                        className="otp-input"
                        style={{ marginRight: "10px", borderRadius: "0" }}
                      />
                    ))}
                  </CInputGroup>

                  <CRow>
                    <CCol xs={6} className="mb-4">
                      <CButton
                        type="submit"
                        className="bg-black text-white border-b px-4"
                      >
                        Verify
                      </CButton>
                    </CCol>
                    <CCol xs={6} className="mb-4">
                      <CButton
                        type="button"
                        onClick={handleResendOtp}
                        className="bg-black text-white border-b"
                      >
                        Resend OTP
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <CCard className="text-white bg-dark ">
              <CCardBody className="text-center">
                <div
                  className=" d-flex mt-3"
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <img
                    src={logos}
                    alt=""
                    width="260"
                    className="sidebar-brand-full"
                  />
                </div>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  );
};

export default OtpVerify;
