import React, { Component, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./scss/style.scss";
import ForgetPassword from "./views/pages/login/ForgetPassword";
import OtpVerify from "./views/pages/login/OtpVerify";
import ResetPassword from "./views/pages/login/ResetPassword";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));
// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>
           
            <Route exact path="/" name="Login Page" element={<Login />} />
            <Route exact path="/forget-password" name="Forget Page" element={<ForgetPassword />} />
            <Route exact path="/otp-verify" name="Otp Page" element={<OtpVerify />} />
            <Route exact path="/new-password" name="Password Page" element={<ResetPassword />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
